<template>
  <section>
    <div class="row">
      <div class="col-md-4">
        <span>Credits &copy; 2021 - All Rights Reserved</span>
      </div>

      <div class="col-md-8">
        <nav>
          <ul>
            <li><a href="#">Link 1</a></li>
            <li><a href="#">Link 2</a></li>
            <li><a href="#">Link 3</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "footer-credits",
  components: {},
  props: ["styles"],
  data: function() {
    return {};
  }
};
</script>

<style scoped lang="scss">
section {
  // background-color: #252935;
  overflow: hidden;

  padding: 25px 15px;
  max-width: 1140px;
  margin: auto;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: right;

  li {
    display: inline-block;
    padding: 0 10px;
  }
}
</style>
