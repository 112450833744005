<template>
    <section
        :class="[
            'space-top-' + section.spacing.top,
            'space-bottom-' + section.spacing.bottom,
        ]"
        :style="regenerateSectionCSS()"
        >

        <options @section-colors-changed="updateSectionColors($event)"  @section-remove="removePageSection(sectionID)" :index="index" colors="{ actions: true, basic: true, background: true }"></options>

        <div :class="['align--' + section.align, 'cmst']">
            <toolbar
                @update-alignment="updateSectionAlignment($event)"
                type="align-h"
                position="middle"
            ></toolbar>

            <h1 class="title" contenteditable="true">
                Frequently Asked Questions
            </h1>

            <p contenteditable="true">
                Astfel, acordam importanta egala fiecarei lucrari, dispunem de
                personal calificat, de logistica necesara desfasurarii activitatii
                si de substante avizate de Ministerul Sanatatii
            </p>
        </div>
 
        <ul class="faq cmst">

            <li :key="'question-' + index" v-for="(question, index) in items">
                <input
                    type="radio"
                    name="question"
                    :checked="{ 'true': index === 0 }"
                    :id="'question-' + index"
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.058"
                    height="9.635"
                    viewBox="0 0 16.058 9.635"
                    class="chevron"
                >
                    <path
                        d="M8.653,9.379,15.8,2.314a.866.866,0,0,0,0-1.234L14.966.256a.89.89,0,0,0-1.247,0l-5.69,5.6L2.339.254a.89.89,0,0,0-1.247,0L.258,1.08a.866.866,0,0,0,0,1.234L7.4,9.379A.89.89,0,0,0,8.653,9.379Z"
                    />
                </svg>
                <label :for="'question-' + index"
                    >{{ question.title }}</label
                >
                <p>{{ question.description }}</p>
            </li>
          
            <toolbar
                @list-add="listElementsAdd()"
                @list-remove="listElementsRemove()"
                type="items-ar"
                position="bottom"
            ></toolbar>

        </ul>


        <spacing
            @section-spacing-top="updateSectionSpacingTop($event)"
            position="top"
            :size="section.spacing.top"
        ></spacing>
        <spacing
            @section-spacing-bottom="updateSectionSpacingBottom($event)"
            position="bottom"
            :size="section.spacing.bottom"
        ></spacing>
    </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import toolbar from "../section-toolbar.vue";
import options from "../section-options.vue";
import spacing from "../section-spacing.vue";

export default {
    name: "faq-full",
    components: {
        toolbar,
        options,
        spacing,
    },
    data: function() {
        return {
            section: {
                align: "left", // left | center | right
                spacing: {
                    top: "lg",
                    bottom: "lg",
                },
                colors: {
                    action: 0,
                    active: 0,
                    basic: 0,
                    background: 0,
                }
            },
            items: [
                {
                    title: "Some question 1",
                    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
                },
                {
                    title: "Some question 2",
                    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
                },
                {
                    title: "Some question 3",
                    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
                },
                {
                    title: "Some question 4",
                    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
                }
            ]
        };
    },
    props: ["styles", "index", "sectionID"],
    methods: {
        created() {
            console.log('Section Index: ' + this.index);
        },

        regenerateSectionCSS(){
            let computedColors = {};

            computedColors["--section-color-basic"] = this.allColors.basic[ this.section.colors.basic ];
            computedColors["--section-color-action"] = this.allColors.action[ this.section.colors.action ];
            computedColors["--section-color-active"] = this.allColors.action[ this.section.colors.active ];
            computedColors["--section-color-background"] = this.allColors.background[ this.section.colors.background ];

            return computedColors;
        },

        updateSectionColors(colors){
            this.section.colors = colors;
        },

        updateSectionSpacingTop(spacing) {
            this.section.spacing.top = spacing;
        },

        updateSectionSpacingBottom(spacing) {
            this.section.spacing.bottom = spacing;
        },

        updateSectionAlignment(align) {
            this.section.align = align;
        },

        listElementsRemove() {
            this.items.pop();
        },

        listElementsAdd() {
            this.items.push(                {
                    title: "Some question " + (this.items.length + 1),
                    description: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
                })
        },

        ...mapActions(["removePageSection"]),
    },
    computed: {
        ...mapGetters(["allColors"])
    },
};
</script>

<style scoped lang="scss">
    section{
        background: var(--section-color-background);
        color: var(--section-color-basic);
    }
    ul {
        padding: 25px 0 0 0;
        list-style-type: none;
        margin: 0;
        position: relative;
    
        li {
            padding: 0;
            position: relative;
            
            
            & + li {
                border-top:1px solid #ECEEF2;
            }

            p {
                transition: all .45s;
                height:auto;
                overflow:hidden;
                max-height:0;
                margin:0;
                padding:0;
            }
        }
    }

    .chevron {
        display: block;
        position: absolute;
        top: 35px;
        right: 15px;
        transition: all 0.25s;
        width:12px;
    }

    label {
        // background-color:rgba(255,0,0,.5);
        font-size: 20px;
        cursor: pointer;
        display: block;
        padding:20px 20px 20px 0;
        position:relative;
        z-index: 10;
    }



    input {
        display: none;

        &:checked {

            & + .chevron {
                transform: rotate(180deg);
                fill: var(--section-color-action);

                & + label {
                    color: var(--section-color-action);
 
                    & + p {
                        max-height:600px;
                        padding:5px 0;
                    }
                }
            }
        }
    }

</style>
