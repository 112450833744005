<template>
  <div class="wrapper">
    <header>
      <h1>Layout generator</h1>
      <div class="handle"><svg xmlns="http://www.w3.org/2000/svg" width="11.675" height="6.778" viewBox="0 0 11.675 6.778"><path d="M194.946,290.408l4.6,4.7,4.628-4.7c.61-.636,1.653.407,1.017,1.043l-5.213,5.366a.568.568,0,0,1-.814,0l-5.264-5.366C193.293,290.815,194.336,289.772,194.946,290.408Z" transform="translate(205.393 296.988) rotate(180)" fill="#fefefe" fill-rule="evenodd"/></svg></div>
    </header>
    <div id="cms" :style="colors">

      <main>     
        <toolbar v-bind:sections="sections"></toolbar>
        <colors @color-update="generateColorVariables"></colors>
        
        <div id="website">
          <div :key="section.id" v-for="(section, index) in allSections">
            <sectionFAQFull
              :index="index"
              :sectionID="section.id"
              v-if="section.type == 'faq-full'"
            ></sectionFAQFull>
            <sectionCTAFull
              :index="index"
              :sectionID="section.id"
              v-if="section.type == 'cta-full'"
            ></sectionCTAFull>
            <sectionCTAImgLeft
              :index="index"
              :sectionID="section.id"
              v-if="section.type == 'cta-img-left'"
            ></sectionCTAImgLeft>
            <sectionCTAImgRight
              :index="index"
              :sectionID="section.id"
              v-if="section.type == 'cta-img-right'"
            ></sectionCTAImgRight>
            <sectionFooterSimple
              :index="index"
              :sectionID="section.id"
              v-if="section.type == 'footer-simple'"
            ></sectionFooterSimple>
            <sectionFooterCredits
              :index="index"
              :sectionID="section.id"
              v-if="section.type == 'footer-credits'"
            ></sectionFooterCredits>
          </div>
        </div>
      </main>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";

import toolbar from "./components/app-toolbar.vue";
import colors from "./components/app-colors.vue";

import sectionFAQFull from "./components/sections/faq-full.vue";
import sectionCTAFull from "./components/sections/cta-full.vue";
import sectionCTAImgLeft from "./components/sections/cta-img-left.vue";
import sectionCTAImgRight from "./components/sections/cta-img-right.vue";
import sectionFooterSimple from "./components/sections/footer-simple.vue";
import sectionFooterCredits from "./components/sections/footer-credits.vue";

export default {
  name: "CMS",
  components: {
    toolbar,
    colors,
    sectionFAQFull,
    sectionCTAFull,
    sectionCTAImgLeft,
    sectionCTAImgRight,
    sectionFooterSimple,
    sectionFooterCredits
  },

  methods: {
    generateColorVariables(){
        console.log('Color Variables Update');

        let computedColors = {};

        for (let i = 0; i < this.allColors.basic.length; i++) {
          computedColors["--color-basic-" + i] = this.allColors.basic[i];
        }

        for (let i = 0; i < this.allColors.action.length; i++) {
          computedColors["--color-action-" + i] = this.allColors.action[i];
        }

        for (let i = 0; i < this.allColors.background.length; i++) {
          computedColors["--color-background-" + i] = this.allColors.background[i];
        }

        this.colors = computedColors;
    },
  },

  created: function () {
      this.generateColorVariables()
  },



  computed: {
    ...mapGetters(["allSections", "allColors"])
  },

  watch: { 
    allColors: function(newColors){
      console.log('updated color single :' + newColors);
    }
  },

  data: function() {
    return {
      colors: {},
      sections: {
        types: [
          {
            title: "Header",
            type: "header"
          },
          {
            title: "Footer",
            type: "footer"
          },
          {
            title: "Navigation",
            type: "navigation"
          },
          {
            title: "FAQ",
            type: "faq"
          },
          {
            title: "Showcase",
            type: "showcase"
          },
          {
            title: "Call to action",
            type: "cta"
          },
          {
            title: "Reviews",
            type: "reviews"
          },
          {
            title: "Forms",
            type: "forms"
          }
        ],

        collection: [
          {
            id: "faq-full",
            type: "faq",
            preview: "preview-section-cta-full.svg"
          },
          {
            id: "cta-full",
            type: "cta",
            preview: "preview-section-cta-full.svg"
          },
          {
            id: "cta-full-inverted",
            type: "cta",
            preview: "preview-section-cta-full-inverted.svg"
          },
          {
            id: "cta-img-left",
            type: "cta",
            preview: "preview-section-cta-img-left.svg"
          },
          {
            id: "cta-img-right",
            type: "cta",
            preview: "preview-section-cta-img-right.svg"
          }
        ]
      },
    };
  }
};
</script>

<style lang="scss">
:root {
  --ui-button-hover-background: #fff;
  --ui-button-hover-color: #fb5d39;

  --spacing-xs: 3vh;
  --spacing-sm: 5vh;
  --spacing-md: 10vh;
  --spacing-lg: 15vh;
}

header {
  background: #141924;
  height:80px;
  width:100%;
  position:relative;
  border-bottom:2px solid #E1232E;

  h1 {
    font-weight: 300;
    color:#fff;
    padding-left:30px;
    margin:0;
    line-height: 80px;
    font-size:24px;
  }

  .handle {
    width:80px;
    right:100px;
    bottom:-18px;
    height:18px;
    border-radius:0 0 6px 6px;
    position:absolute;
    background: #E1232E;

    display:flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  font-size: 14px;
}

.align--center {
  text-align: center;
}
.align--left {
  text-align: left;
}
.align--right {
  text-align: right;
}

section:hover .section-move-up,
section:hover .section-move-down,
section:hover .section-colors,
section:hover .section-colors-options,
section:hover .section-remove {
  right: 15px;
}

.cmst:hover .section-toolbar,
h1:hover .section-toolbar,
h2:hover .section-toolbar,
h3:hover .section-toolbar,
h4:hover .section-toolbar,
h5:hover .section-toolbar,
section:hover > .section-toolbar,
.row:hover > div > .section-toolbar {
  opacity: 1;
}

section > div.cmst {
    position:relative;
}

section:hover .section-spacing {
  opacity: 0.1;
}

// Spacing 

.space-top-xs {
  padding-top: var(--spacing-xs);
}
.space-top-sm {
  padding-top: var(--spacing-sm);
}
.space-top-md {
  padding-top: var(--spacing-md);
}
.space-top-lg {
  padding-top: var(--spacing-lg);
}

.space-bottom-xs {
  padding-bottom: var(--spacing-xs);
}
.space-bottom-sm {
  padding-bottom: var(--spacing-sm);
}
.space-bottom-md {
  padding-bottom: var(--spacing-md);
}
.space-bottom-lg {
  padding-bottom: var(--spacing-lg);
}

//  Sections
//
section {
  position: relative;
  padding: var(--spacing-md) 15px;
  max-width: 1140px;
  margin: auto;
  box-sizing: border-box;
  transition: all 0.25s;

  &:hover {
    box-shadow: inset 0 0 0 2px rgba(237, 237, 254, 1);
  }

  .title {
    position: relative;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }
}

//  Buttons
//
.btn {
  display: inline-block;
  padding: 15px 20px;
  background: var(--button-background);
  color: var(--button-color);
  border-radius: var(--radius-buttons);
  text-decoration: none;
  transition: .25 all;
  
  &:hover {
    background: var(--button-hover-background);
    color: var(--button-hover-color);
  }

  & + .btn {
    margin-left: 15px;
  }

  &.btn--secondary {
    color: var(--button-secondary-color);
    background: transparent;

    &:hover {
      color: var(--button-secondary-hover-color);
    }
  }
}

#cms {
  display: flex;
  height: 100%;
}

#website {
  margin: 20px 195px 40px 175px;
  border: 1px solid #eee;
  min-height: 450px;
}

main {
  justify-content: flex-start;
  align-content: flex-start;
  flex-grow: 1;
  margin: 0;
  position: relative;
}
</style>
