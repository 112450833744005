import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    template: {
      fonts: {},
      colors: {
        action: ["#F94C3B", "#35363A", "#FFC857", "#177E89"],
        basic: ["#000", "#555", "#999", "#fff"],
        background: ["#ffffff", "#177E89"]
      }
    },
    currentPage: 0,
    pages: [{
      title: "Home",
      sections: [
        {
          id: "sec1",
          type: "faq-full",
          order: 3,
          styles: {
            colors: {
              action: null,
              active: null,
              basic: null,
              background: null,
            }
          }
        },
        {
          id: "teaser23432",
          type: "cta-full",
          order: 4,
        },
        {
          id: "teaser234",
          type: "footer-simple",
          order: 5,
        },
        {
          id: "teaser24",
          type: "footer-credits",
          order: 6,
        }
      ]
    },
    ]
  },
  getters: {

    allSections: state => state.pages[state.currentPage].sections,

    allColors: state => state.template.colors,

  },
  mutations: {

    ADD_SECTION: (state, section) => {
      state.pages[state.currentPage].sections.push(section);
    },

    REMOVE_SECTION: (state, section_id) => {
      state.pages[state.currentPage].sections.forEach((item, index) => {
        if (item.id == section_id) {
          state.pages[state.currentPage].sections.splice(index, 1);
        }
      });
    },

    COLOR_CHANGE(state, data) {
      state.template.colors[data.type][data.index] = data.color;
    },

    COLOR_REMOVE(state, data) {
      state.template.colors[data.type].splice(data.index, 1);
    },

    COLOR_ADD(state, type) {
      state.template.colors.[type].push('#FF0000');
    }

  },
  actions: {

    addPageSection(context, section) {
      context.commit("ADD_SECTION", section);
    },

    removePageSection(context, section_id) {
      context.commit("REMOVE_SECTION", section_id);
    },

    colorChange(context, data) {
      context.commit("COLOR_CHANGE", data);
    },

    colorRemove(context, data) {
      context.commit("COLOR_REMOVE", data);
    },

    colorAdd(context, type) {
      context.commit("COLOR_ADD", type);
    }

  },
  modules: {}
});
