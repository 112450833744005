<template>
  <div :class="['section-toolbar', 'position-' + position]">
    <div>

      <ul :class="['show-' + align, type]" v-if="type == 'align-h'">
        <li v-on:click="changeAlignment('left')" :class="getAlignmentClass('left')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="13"
            viewBox="0 0 15 13"
          >
            <path
              d="M-5768,69V67.279h14V69Zm4.9-3.426V63.853h9v1.721Zm-4.9-3.426V60.426h14v1.722Zm4.9-3.426V57h9v1.722Z"
              transform="translate(5768.5 -56.5)"
              fill="#666"
              stroke="rgba(0,0,0,0)"
              stroke-miterlimit="10"
              stroke-width="1"
            />
          </svg>
        </li>
        <li v-on:click="changeAlignment('center')" :class="getAlignmentClass('center')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="13"
            viewBox="0 0 15 13"
          >
            <path
              d="M-5768,69V67.279h14V69Zm2.8-3.426V63.853h9v1.721Zm-2.8-3.426V60.426h14v1.722Zm2.8-3.426V57h9v1.722Z"
              transform="translate(5768.5 -56.5)"
              fill="#fff"
              stroke="rgba(0,0,0,0)"
              stroke-miterlimit="10"
              stroke-width="1"
            />
          </svg>
        </li>
        <li v-on:click="changeAlignment('right')" :class="getAlignmentClass('right')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="13"
            viewBox="0 0 15 13"
          >
            <path
              d="M-5768,69V67.279h14V69Zm0-3.426V63.853h9v1.721Zm0-3.426V60.426h14v1.722Zm0-3.426V57h9v1.722Z"
              transform="translate(5768.5 -56.5)"
              fill="#666"
              stroke="rgba(0,0,0,0)"
              stroke-miterlimit="10"
              stroke-width="1"
            />
          </svg>
        </li>
      </ul>

      <ul :class="[type]" v-if="type == 'items-ar'">
        <li @click="listElementsAdd()">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path d="M-1128-2360v-5h-5v-4h5v-5h4v5h5v4h-5v5Z" transform="translate(1133.5 2374.5)" fill="#666666" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg>
        </li>
        <li @click="listElementsRemove()">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="5" viewBox="0 0 15 5"><rect width="14" height="4" transform="translate(0.5 0.5)" fill="#666666" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg>
        </li>
      </ul>

    </div>
  </div>
</template>
<script>
export default {
  name: "toolbar",
  props: ["type", "position"],
  // emits: ['update-alignment'],
  data: function() {
    return {

      align: ""

    };
  },
  methods: {

    listElementsRemove(){
      this.$emit("list-remove");
    },

    listElementsAdd(){
      this.$emit("list-add");
    },

    changeAlignment(newAlignment) {
      this.align = newAlignment;
      this.$emit("update-alignment", newAlignment);

      console.log("Alignment: " + newAlignment);
    },

    getAlignmentClass(currentValue) {
      if (this.align == currentValue) {
        return "active";
      }
    }

  }
};
</script>
<style lang="scss" scoped>

.section-toolbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #f94c3b;
  position: absolute;
  
  left: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 9999;

  &.position-top {
    top: -3px;
  }

  &.position-bottom {
    bottom:0;
  }

  > div {
    width: 6px;
    height: 6px;
    position: relative;
  }

  &:hover ul {
    display: flex;
  }

  ul {
    background: #404247;
    position: absolute;
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 90px;
    left: 0;
    border-radius: 6px;
    overflow: hidden;
    display: none;

    &.show-middle {
      top: -13px;
    }

    &.align-h {
      width: 90px;
      left: -45px;
    }

    &.items-ar {
      width: 60px;
      left: -30px;
    }

    li.active {
      background: #fb5d39;

      svg path {
        fill: #fff !important;
      }

      svg rect {
        fill: #fff !important;
      }
    }

    li {
      // background:rgba(255,0,0,.3); 
      height: 30px;
      width: 30px;
      margin-left: 1px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg path {
        fill: #666 !important;
      }

      &:hover svg path {
        fill: #fff !important;
      }

      &:hover svg rect {
        fill: #fff !important;
      }
    }
  }
}
</style>
