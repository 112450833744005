<template>
    
    <div :class="getModeClass()">
        <div class="panel--colors-wrapper">
            <a @click="changeMode()" class="panel--edit">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.547,2.133a.429.429,0,0,1,.621,0h0L13.832,4.8a.429.429,0,0,1,0,.621h0L6.286,12.964a.97.97,0,0,1-.222.133h0l-3.551.888a.441.441,0,0,1-.533-.533h0L2.868,9.9c0-.089.044-.133.133-.222H3ZM9.083,4.841,3.712,10.212l-.666,2.708,2.708-.666,5.371-5.371Zm1.775-1.775L9.7,4.219l2.042,2.042L12.9,5.107Z" transform="translate(-1.965 -2)" fill="#353943"/></svg>
            </a>

            <h4>Colors</h4>

            <div class="colors">
                <h5>
                    Actions
                    <svg @click="colorAdd('action')" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9"><path d="M-1127-2363v-3h-3v-2h3v-3h2v3h3v2h-3v3Z" transform="translate(1130.5 2371.5)" fill="#666" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg>
                </h5>
                <ul>
                    <li :color="color" :key="index" v-for="(color, index) in allColors.action"> 
                        <color @color-update="colorUpdate()" :edit="edit" :index="index" :color="color" type="action"></color>
                    </li>
                </ul>
            </div> 

            <div>
                <h5>
                    Basic
                    <svg @click="colorAdd('basic')" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9"><path d="M-1127-2363v-3h-3v-2h3v-3h2v3h3v2h-3v3Z" transform="translate(1130.5 2371.5)" fill="#666" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg>
                </h5>
                <ul>
                    <li :color="color" :key="index" v-for="(color, index) in allColors.basic"> 
                        <color @color-update="colorUpdate()" :edit="edit" :index="index" :color="color" type="basic"></color>
                    </li>
                </ul>
            </div> 

            <div>
                <h5>
                    Background
                    <svg @click="colorAdd('background')" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9"><path d="M-1127-2363v-3h-3v-2h3v-3h2v3h3v2h-3v3Z" transform="translate(1130.5 2371.5)" fill="#666" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg>
                </h5>
                <ul>
                    <li :color="color" :key="index" v-for="(color, index) in allColors.background"> 
                        <color @color-update="colorUpdate()" :edit="edit" :index="index" :color="color" type="background"></color>
                    </li>
                </ul>
            </div>
        </div>
    </div> 

</template>
<script>

import { mapGetters, mapActions } from "vuex";
import color from "../components/color-picker"; 

export default {
    name:"app-colors",
    data(){
        return {
            edit: false
        }
    },
    props: ["styles"],
    components: {
        color,
    },
    computed: {
        ...mapGetters(["allColors"])
    },
    methods: {

        getModeClass(){
            if (this.edit) {
                return 'panel-app panel--colors mode-edit';
            }else{
                return 'panel-app panel--colors';
            }
        }, 

        changeMode(){
            if (this.edit) {
                this.edit = false;
            }else{
                this.edit = true;
            }
        },

        colorUpdate(){
            this.$emit("color-update");
        },

        ...mapActions(["colorAdd"]),
    }
}
</script>
<style lang="scss">

    .panel--colors {
        
        &.mode-edit {
            // background:#CBB18E;
        }

        .panel--edit {
            width:24px;
            height:24px;
            position:absolute;
            background: #fff;
            right:0;
            top:-27px;
            border-radius:15px;
            box-shadow: 0 0 2px 0 rgba(0,0,0,.2);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;
        }

        .panel--colors-wrapper {
            position: relative;
        }

        .panel--colors-wrapper > div {
            position: relative; 
            padding-bottom:25px;
            width:100%;
            // background:pink;
            margin-bottom: 10px;;
        }

        h5 {
            font-weight: normal;
            font-size:11px;
            line-height: 16px;;
            height:16px;
            color: #353943;
            // background:red;
            margin:0;
            position:absolute;
            left:0;
            top:0;
            transition: font-size .25s;

            svg { 
                display:none;

                path {
                    fill: #353943;
                }
            }
        }

        ul {
            // background: gold;
            list-style-type:none;
            margin:0;
            padding:0;
            display:flex;
            justify-content: flex-end;
            position:absolute;
            right:0;
            top:0;

            li {
                width:16px;
                height:16px;
                margin:0 0 0 5px;
                padding:0;

                // background:green;
                // border-radius:15px;
            }
        }


        &.mode-edit {
            h5 {
                position: relative;
                font-weight: normal;
                font-size:14px;
                color: #353943;

                svg {
                    display: block;
                    position: absolute;
                    right:3px;
                    top:4px;
                    cursor:pointer;
                }
            }

            ul {
                position: relative;
                padding:15px 0 0 0;
                display: block;
            }

            li {
                margin-bottom: 10px;
                width:100%;
                border-radius:0;
                height:24px;
            }


            .panel--colors-wrapper > div {
                padding-bottom: 15px;
            }

        }
    }

    .panel-app {
        background: #E6E6EA;
        width:130px;
        padding:15px;
        position: absolute;
        right:20px;
        top:20px;
        min-height: 80px;
        border-radius: 6px;

        h4 {
            margin-top:0;
            font-weight: normal;
            font-size:14px;
            color: #353943;
        }
    }

</style>