<template>
  <div
    @click="changeSize"
    :class="['section-spacing', 'spacing-' + position, 'size-' + size]"
  ></div>
</template>
<script>
export default {
  name: "spacing",
  props: ["position", "size", "index"],
  methods: {
    changeSize() {
      let newSize = "";

      if (this.size == "xs") {
        newSize = "sm";
      } else if (this.size == "sm") {
        newSize = "md";
      } else if (this.size == "md") {
        newSize = "lg";
      } else if (this.size == "lg") {
        newSize = "xs";
      }

      this.$emit("section-spacing-" + this.position, newSize);
    },

    mount() {
      console.log("Start with index: " + this.index);
    }
  }
};
</script>
<style lang="scss" scoped>
.size-xs {
  height: var(--spacing-xs);
}
.size-sm {
  height: var(--spacing-sm);
}
.size-md {
  height: var(--spacing-md);
}
.size-lg {
  height: var(--spacing-lg);
}

.section-spacing {
  position: absolute;
  left: 0;
  width: 100%;

  opacity: 0;

  background-color: #e5e5f7;
  background-size: 8px 8px;
  background-image: repeating-linear-gradient(
    45deg,
    #444cf7 0,
    #444cf7 0.8px,
    #e5e5f7 0,
    #e5e5f7 50%
  );

  transition: all 0.25s;
  cursor: pointer;

  &.spacing-top {
    top: 0;
  }
  &.spacing-bottom {
    bottom: 0;
  }
}
</style>
