<template>
  <div class="options-group">
    <a @click="sectionRemove" class="section-remove">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
      >
        <path
          d="M139.9,89.956v4.853h-.6V89.956ZM138.962,85h4.823c.6,0,.769.454.769.971h2.391c.561,0,.582,1.2,0,1.2h-.457v9.664A2.175,2.175,0,0,1,144.3,99h-5.862a2.175,2.175,0,0,1-2.183-2.168V87.168H135.8c-.582,0-.561-1.2,0-1.2h2.391C138.193,85.475,138.38,85,138.962,85Zm4.823.6h-4.823c-.208,0-.166.227-.166.351h5.155C143.951,85.805,143.993,85.6,143.785,85.6Zm1.684,1.549H137.3v9.664a1.158,1.158,0,0,0,1.164,1.156h5.862a1.158,1.158,0,0,0,1.164-1.156V87.147Zm-3.783,1.8V95.8h-.6V88.944Zm1.767,1.012v4.853h-.6V89.956Z"
          transform="translate(-135.373 -85)"
          fill="#404247"
        />
      </svg>
    </a>
    <a @click="$emit('section-move-up')" class="section-move-up">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.675"
        height="6.778"
        viewBox="0 0 11.675 6.778"
      >
        <path
          d="M194.946,290.408l4.6,4.7,4.628-4.7c.61-.636,1.653.407,1.017,1.043l-5.213,5.366a.568.568,0,0,1-.814,0l-5.264-5.366C193.293,290.815,194.336,289.772,194.946,290.408Z"
          transform="translate(205.393 296.988) rotate(180)"
          fill="#404247"
          fill-rule="evenodd"
        />
      </svg>
    </a>
    <a @click="$emit('section-move-down')" class="section-move-down">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.674"
        height="6.778"
        viewBox="0 0 11.674 6.778"
      >
        <path
          d="M1.228,6.58l4.6-4.7,4.628,4.7c.61.636,1.653-.407,1.017-1.043L6.263.172a.568.568,0,0,0-.814,0L.185,5.538C-.425,6.173.617,7.216,1.228,6.58Z"
          transform="translate(11.674 6.778) rotate(180)"
          fill="#404247"
          fill-rule="evenodd"
        />
      </svg>
    </a>
    <a href="#" class="section-colors">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.103" height="14.04" viewBox="0 0 12.103 14.04"><path d="M-1119.454-1196.984a6.051,6.051,0,0,1-2.9-1.024,6.11,6.11,0,0,1-1.99-2.255,6.08,6.08,0,0,1-.648-3.113,5.955,5.955,0,0,1,.4-1.824,6.585,6.585,0,0,1,.424-.882,6.462,6.462,0,0,1,.569-.8,16.067,16.067,0,0,1,1.526-1.557c.587-.551,1.185-1.086,1.79-1.617.287-.25.574-.5.864-.749a.738.738,0,0,1,.467-.193.69.69,0,0,1,.466.193l.01.009c.238.2.473.407.707.611q.7.606,1.375,1.227c.487.444.967.893,1.429,1.361a7.6,7.6,0,0,1,1.313,1.667,6.127,6.127,0,0,1,.75,2.92h0a5.649,5.649,0,0,1-.046.706,5.933,5.933,0,0,1-.379,1.514,6.036,6.036,0,0,1-.735,1.336,6.083,6.083,0,0,1-2.318,1.918,6.172,6.172,0,0,1-2.562.577Q-1119.2-1196.96-1119.454-1196.984Zm.507-1.3v-11.184q-.964.836-1.9,1.7c-.359.331-.714.666-1.06,1.013a10.243,10.243,0,0,0-.774.839,5.456,5.456,0,0,0-.632,1.084,5.452,5.452,0,0,0-.322,1.2,5.622,5.622,0,0,0,0,1.26c0-.027-.008-.055-.012-.081q.006.041.012.082c0,.031.007.062.011.093,0-.03-.007-.061-.011-.092a5.426,5.426,0,0,0,.316,1.171,5.477,5.477,0,0,0,.645,1.1,5.536,5.536,0,0,0,.809.81,5.531,5.531,0,0,0,1.1.647,5.46,5.46,0,0,0,1.2.322A5.621,5.621,0,0,0-1118.948-1198.281Zm-.554-.026Zm-1.209-.308Zm-.08-.034-.078-.032Zm-.982-.561-.069-.054Zm-.869-.84Zm-.073-.094-.031-.039Zm-.56-.943-.029-.071Zm-.021-3.8.021-.052Zm-.341,2.512h0v0Z" transform="translate(1125 1211)" fill="#404247"/></svg>
    </a>

    <div class="section-colors-options">

        <div class="color-selector" @click="changeActionColor"> 
          <div class="preview" :style="getActionColor()"></div>
        </div>

        <div class="color-selector" @click="changeActiveColor"> 
          <div class="preview" :style="getActiveColor()"></div>
        </div>

        <div class="color-selector" @click="changeBasicColor"> 
          <div class="preview" :style="getBasicColor()"></div>
        </div>
        
        <div class="color-selector" @click="changeBackgroundColor"> 
          <div class="preview" :style="getBackgroundColor()"></div>
        </div>

    </div>
  </div>
</template>
<script>
import { 
  mapGetters,  
  // mapActions
 } from "vuex";

export default {
  name: "options",
  data(){
      return {
          settings: {
            colors: {

              action: 0,
              active: 0,
              basic: 0,
              background: 0,

            }
          }
      }
  },

  computed: {
      ...mapGetters(["allColors"])
  },

  methods: {

    getBackgroundColor() { 
      return 'background-color:'  + this.allColors.background[this.settings.colors.background];
    }, 

    getBasicColor() { 
      return 'background-color:'  + this.allColors.basic[this.settings.colors.basic];
    }, 

    getActionColor() { 
      return 'background-color:'  + this.allColors.action[this.settings.colors.action];
    }, 
    
    getActiveColor() { 
      return 'background-color:'  + this.allColors.action[this.settings.colors.active];
    }, 

    changeActionColor() {
      if (this.settings.colors.action <  this.allColors.action.length){
        this.settings.colors.action ++;
      }else{
        this.settings.colors.action = 0;
      }

      this.$emit("section-colors-changed", this.settings.colors);
    },

    changeActiveColor() {
      if (this.settings.colors.active <  this.allColors.action.length){
        this.settings.colors.active ++;
      }else{
        this.settings.colors.active = 0;
      }

      this.$emit("section-colors-changed", this.settings.colors);
     },

    changeBasicColor() { 
      if (this.settings.colors.basic <  this.allColors.action.length){
        this.settings.colors.basic ++;
      }else{
        this.settings.colors.basic = 0;
      }

      this.$emit("section-colors-changed", this.settings.colors);
     },
    changeBackgroundColor() { 
      if (this.settings.colors.background <  this.allColors.background.length){
        this.settings.colors.background ++;
      }else{
        this.settings.colors.background = 0;
      }

      this.$emit("section-colors-changed", this.settings.colors);
     },

    sectionColorChanged(){
      this.$emit("section-colors-changed", this.settings.colors);
    },

    sectionRemove() {
      this.$emit("section-remove");
    },

  },
  props: ["colors", "index"],
  created: function () {
    console.log(this.colors + ' -  index: ' + this.index );
  }
};
</script>
<style lang="scss" scoped>

.color-selector {
  position:relative;
  // background: rgba(255,0,0,0.3);
  height:22px;

  .preview {
    background:orange;
    border-radius:10px;
    width:16px;
    height:16px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    position:absolute;
    top:3px;
    left:7px;
    cursor:pointer;
    transition: background-color .25s;
  }

}


.options-group {
  // background:red;
  width: 60px;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.section-colors-options {
  opacity: 0;
  right: -60px;
  position: absolute;
  width:30px;
  min-height:80px;
  background:#fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  z-index:80;
  border-radius: 0 0 30px 30px;
  transition: all 0.25s;
  padding-top:25px;
  padding-bottom:5px;
}

.section-move-up,
.section-move-down,
.section-colors,
.section-remove {
  position: absolute;
  right: -60px;
  transition: all 0.25s;
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor:pointer;

  &:hover {
    background-color: var(--ui-button-hover-background);
    svg path {
      fill: var(--ui-button-hover-color) !important;
    }
  }
}

.section-move-up {
  top: 60px;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px 30px 0 0;
}

.section-move-down {
  top: 90px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 30px 30px;
}

.section-colors {
  top: 135px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

.section-colors:hover + .section-colors-options {
  opacity: 1;
}

.section-colors-options {
  top:145px;

  &:hover {
    opacity: 1;
  }
}

.section-remove {
  top: 15px;
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
