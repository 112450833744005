<template>
    <div :class="['color', getModeClass()]" >
        <div class="preview" :style="'background-color:' + currentColor + ';'"></div>
        <input type="text" v-model.lazy="currentColor" @change="eventChangeColor" />
        <div @click="removeCurrentColor()" class="remove" ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
      >
        <path
          d="M139.9,89.956v4.853h-.6V89.956ZM138.962,85h4.823c.6,0,.769.454.769.971h2.391c.561,0,.582,1.2,0,1.2h-.457v9.664A2.175,2.175,0,0,1,144.3,99h-5.862a2.175,2.175,0,0,1-2.183-2.168V87.168H135.8c-.582,0-.561-1.2,0-1.2h2.391C138.193,85.475,138.38,85,138.962,85Zm4.823.6h-4.823c-.208,0-.166.227-.166.351h5.155C143.951,85.805,143.993,85.6,143.785,85.6Zm1.684,1.549H137.3v9.664a1.158,1.158,0,0,0,1.164,1.156h5.862a1.158,1.158,0,0,0,1.164-1.156V87.147Zm-3.783,1.8V95.8h-.6V88.944Zm1.767,1.012v4.853h-.6V89.956Z"
          transform="translate(-135.373 -85)"
          fill="#404247"
        />
      </svg></div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"color-picker",

    props: ["edit", "color", "index", "type"],
    
    data: function() {
        return {
            currentColor: null
        };
    },

    created: function () {
        this.currentColor = this.color;
    },

    methods: {

        getModeClass(){
            if (this.edit){
                return 'mode-edit';
            } 
        },

        eventChangeColor(){
            this.colorChange({ type: this.type , index: this.index,  color: this.currentColor});

                console.log('eventChangeColor - Picker emit color update');
                this.$emit("color-update");
        },

        removeCurrentColor(){
            this.colorRemove({type: this.type, index: this.index, color: this.currentColor})
            // this.$destroy();
            // this.$el.parentNode.removeChild(this.$el);
        },

        ...mapActions(["colorChange", "colorRemove"]),
    },
    watch: { 
            color: function(newColor) {
                this.currentColor = newColor;

                console.log('WATCH - Picker emit color update');
                this.$emit("color-update");
            }
        }
}
</script>

<style scoped lang="scss">
    .color {
        position:relative;

        .preview {
            height:16px;
            width:16px;
            border-radius:16px;
            border-radius:1px solid rgba(112,112,112, .5);
            box-shadow:1px 1px 2px 0 rgba(0,0,0,.1);
            z-index:5;
        }

        input {
            top:-1px;
            left:-1px;
            position:absolute;
            display:none;
            height:24px;
            width:60px;
            font-size:11px;
            font-weight:bold;
            border-radius: 24px;
            padding-left:35px;
            color:#4042479a;
            
            &:focus {
                outline: none;
                box-shadow: inset 0 0 4px rgba(40, 133, 255, 0.2);
                color:#222;
            }
        }

        .remove { display:none; }

        &.mode-edit {

            .remove {
                width:24px;
                height:24px;
                background:#fff;
                position:absolute;
                right:0;
                top:0;
                display:flex;
                border-radius:24px;
                justify-content: center;
                align-items: center;
                z-index: 100;

                svg {
                    display:block;
                    width:10px !important;
                }

                cursor:pointer;
            }

            input {
                display: block;
                border:none;
            }

            .preview{
                position:relative;
                z-index:10;
                height:24px;
                width:24px;
                border-radius:24px;
            }
        }
    }
</style>