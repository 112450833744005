<template>
  <section
    :class="[
      'align--' + section.align,
      'space-top-' + section.spacing.top,
      'space-bottom-' + section.spacing.bottom
    ]"
  >
    <options @section-remove="removePageSection(sectionID)"></options>
    <toolbar
      @update-alignment="updateSectionAlignment($event)"
      type="align-h"
      position="middle"
    ></toolbar>

    <spacing
      @section-spacing-top="updateSectionSpacingTop($event)"
      position="top"
      :size="section.spacing.top"
    ></spacing>
    <spacing
      @section-spacing-bottom="updateSectionSpacingBottom($event)"
      position="bottom"
      :size="section.spacing.bottom"
    ></spacing>

    <div class="row">
      <div class="col-md-6">
        <h1 class="title" contenteditable="false">
          Let's Make Things Nice
        </h1>
        <p contenteditable="false">
          Astfel, acordam importanta egala fiecarei lucrari, dispunem de
          personal calificat, de logistica necesara desfasurarii activitatii si
          de substante avizate de Ministerul Sanatatii
        </p>

        <div>
          <a class="btn btn--primary" href="#">Send it</a>
          <a class="btn btn--secondary" href="#">Tell me more</a>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div>
  </section>
</template>

<script>
import toolbar from "../section-toolbar.vue";
import options from "../section-options.vue";
import spacing from "../section-spacing.vue";

import { mapActions } from "vuex";

export default {
  name: "cta-full",
  components: {
    toolbar,
    options,
    spacing
  },
  data: function() {
    return {
      section: {
        align: "left", // left | center | right
        spacing: {
          top: "lg",
          bottom: "lg"
        }
      }
    };
  },
  props: ["styles", "index", "sectionID"],
  methods: {
    updateSectionAlignment: function(align) {
      // console.log('Updated alignment in section ');
      this.section.align = align;
    },

    updateSectionSpacingTop: function(spacing) {
      this.section.spacing.top = spacing;
    },

    updateSectionSpacingBottom: function(spacing) {
      this.section.spacing.bottom = spacing;
    },

    ...mapActions(["removePageSection"])
  }
};
</script>

<style scoped lang="scss"></style>
