<template>
  <section
    :class="[
      'align--' + section.align,
      'row',
      'space-top-' + section.spacing.top,
      'space-bottom-' + section.spacing.bottom
    ]"
  >
    <spacing
      @section-spacing-top="updateSectionSpacingTop($event)"
      position="top"
      :size="section.spacing.top"
    ></spacing>
    <spacing
      @section-spacing-bottom="updateSectionSpacingBottom($event)"
      position="bottom"
      :size="section.spacing.bottom"
    ></spacing>

    <toolbar
      @update-alignment="updateSectionAlignment($event)"
      type="align-h"
      position="top"
    ></toolbar>
    <options @section-remove="removePageSection(sectionID)"></options>

    <div :class="['align--' + section.columns[0].align, 'col-md-6']">
      <toolbar
        @update-alignment="updateColumnAlignment(0, $event)"
        type="align-h"
        position="top"
      ></toolbar>
      <h4>Title</h4>
    </div>
    <div :class="['align--' + section.columns[1].align, 'col-md-2']">
      <toolbar
        @update-alignment="updateColumnAlignment(1, $event)"
        type="align-h"
        position="top"
      ></toolbar>
      <h4>Title</h4>
      <ul>
        <li><a href="#">Link 1</a></li>
        <li><a href="#">Link 2</a></li>
        <li><a href="#">Link 3</a></li>
      </ul>
    </div>
    <div :class="['align--' + section.columns[2].align, 'col-md-2']">
      <toolbar
        @update-alignment="updateColumnAlignment(2, $event)"
        type="align-h"
        position="top"
      ></toolbar>
      <h4>Title</h4>
      <ul>
        <li><a href="#">Link 1</a></li>
        <li><a href="#">Link 2</a></li>
        <li><a href="#">Link 3</a></li>
      </ul>
    </div>
    <div :class="['align--' + section.columns[3].align, 'col-md-2']">
      <toolbar
        @update-alignment="updateColumnAlignment(3, $event)"
        type="align-h"
        position="top"
      ></toolbar>
      <h4>Title</h4>
      <ul>
        <li><a href="#">Link 1</a></li>
        <li><a href="#">Link 2</a></li>
        <li><a href="#">Link 3</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
import toolbar from "../section-toolbar.vue";
import options from "../section-options.vue";
import spacing from "../section-spacing.vue";

import { mapActions } from "vuex";

export default {
  name: "footer-simple",
  components: {
    toolbar,
    spacing,
    options
  },
  props: ["styles", "index", "sectionID"],
  data: function() {
    return {
      section: {
        align: "left",
        columns: [
          { align: "none" },
          { align: "none" },
          { align: "none" },
          { align: "none" }
        ],
        spacing: {
          top: "lg",
          bottom: "lg"
        }
      }
    };
  },
  methods: {
    updateColumnAlignment(column, alignment) {
      this.section.columns[column].align = alignment;
    },

    updateSectionAlignment(alignment) {
      this.section.align = alignment;
    },

    updateSectionSpacingTop: function(spacing) {
      this.section.spacing.top = spacing;
    },

    updateSectionSpacingBottom: function(spacing) {
      this.section.spacing.bottom = spacing;
    },

    ...mapActions(["removePageSection"])
  }
};
</script>

<style scoped lang="scss">
section {
  background-color: rgba(0, 0, 0, 0.02);
  max-width: 1140px;
  margin: auto;
}
</style>
