<template>
  <ul>
    <li v-bind:key="section.type" v-for="section in sections.types">
      <a href="#">{{ section.title }}</a>
      <div class="panel">
        <!-- {{ section.type  }} -->
        <!-- <h1 v-if="awesome">Vue is awesome!</h1>  -->

        <div
          :key="filteredSections.id"
          v-for="filteredSections in getSections(section.type)"
          @click="addNewSection(filteredSections)"
        >
          <img :src="'./assets/images/sections/' + filteredSections.preview" />
          <!-- <strong>{{ filteredSections.preview }}</strong> -->
        </div>
      </div>
    </li>

    <!-- <li><a href="#">Header</a></li>
    <li><a href="#">Navigation</a></li>
    <li><a href="#">Content</a></li>
    <li><a href="#">Slider</a></li>
    <li><a href="#">Projects</a></li>
    <li><a href="#">Blog</a></li>
    <li><a href="#">CTA</a></li>
    <li><a href="#">Services</a></li>
    <li><a href="#">Reviews</a></li>
    <li><a href="#">Footer</a></li> -->
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "toolbar",
  props: ["sections"],

  methods: {
    getSections(sectionType) {
      return this.sections.collection.filter(currentValue => {
        if (currentValue.type == sectionType) {
          return true;
        }
      });
    },
    addNewSection(section) {
      // console.log('Create new section');
      // console.log(section);

      let newSection = {
        id: "section" + this.allSections.length,
        type: section.id,
        order: this.allSections.length,
        settings: {}
      };

      this.addPageSection(newSection);
    },

    ...mapActions([
      "addPageSection" // map `this.incrementBy(amount)` to `this.$store.dispatch('incrementBy', amount)`
    ])
  },

  computed: {
    ...mapGetters(["allSections"])
  }
};
</script>

<style scoped lang="scss">
.panel {
  background: #eee;
  min-height: 600px;
  width: 300px;
  position: absolute;
  left: 155px;
  top: 0;
  display: none;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 999;

  &:hover {
    display: block;
  }

  img {
    cursor: pointer;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background: #f94c3b;
  width: 140px;
  position: absolute;
  top: 20px;
  left: 20px;
  min-height: 200px;
  border-radius: 6px;

  li {
    height: 30px;
  }

  a {
    color: #fff;
    display: block;
    line-height: 30px;
    text-decoration: none;

    &:hover {
      color: var(ui-button-hover-background);
    }
  }

  a:focus + .panel,
  a:hover + .panel {
    display: block;
  }
}
</style>
