<template>
    <section
        :class="[
            'align--' + section.align,
            'space-top-' + section.spacing.top,
            'space-bottom-' + section.spacing.bottom,
        ]"

        :style="regenerateSectionCSS()"
    >
        <options @section-colors-changed="updateSectionColors($event)" @section-remove="removePageSection(sectionID)"></options>
        <toolbar
            @update-alignment="updateSectionAlignment($event)"
            type="align-h"
            position="middle"
        ></toolbar>

        <h1 class="title" contenteditable="true">
            Let's Make Things Nice
        </h1>
        <p contenteditable="true">
            Astfel, acordam importanta egala fiecarei lucrari, dispunem de
            personal calificat, de logistica necesara desfasurarii activitatii
            si de substante avizate de Ministerul Sanatatii
        </p>
        <div>
            <a class="btn btn--primary" href="#">Send it</a>
            <a class="btn btn--secondary" href="#">Tell me more</a>
        </div>

        <spacing
            @section-spacing-top="updateSectionSpacingTop($event)"
            position="top"
            :size="section.spacing.top"
        ></spacing>
        <spacing
            @section-spacing-bottom="updateSectionSpacingBottom($event)"
            position="bottom"
            :size="section.spacing.bottom"
        ></spacing>
    </section>
</template>

<script>
import toolbar from "../section-toolbar.vue";
import options from "../section-options.vue";
import spacing from "../section-spacing.vue";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "cta-full",
    components: {
        toolbar,
        options,
        spacing,
    },
    data: function() {
        return {
            section: {
                align: "left", // left | center | right
                spacing: {
                    top: "lg",
                    bottom: "lg",
                },
                colors: {
                    action: 0,
                    active: 0,
                    basic: 0,
                    background: 0,
                }
            },
        };
    },
    props: ["styles", "index", "sectionID"],
    methods: {
        updateSectionSpacingTop: function(spacing) {
            this.section.spacing.top = spacing;
        },

        updateSectionSpacingBottom: function(spacing) {
            this.section.spacing.bottom = spacing;
        },

        updateSectionAlignment: function(align) {
            this.section.align = align;
        },

        updateSectionColors(colors){
            this.section.colors = colors;
        },
        
        regenerateSectionCSS(){
            let computedColors = {};

            computedColors["--section-color-basic"] = this.allColors.basic[ this.section.colors.basic ];
            computedColors["--section-color-action"] = this.allColors.action[ this.section.colors.action ];
            computedColors["--section-color-active"] = this.allColors.action[ this.section.colors.active ];
            computedColors["--section-color-background"] = this.allColors.background[ this.section.colors.background ];

            return computedColors;
        },

        ...mapActions(["removePageSection"]),
    },
    computed: {
        ...mapGetters(["allColors"])
    },
};
</script>

<style scoped lang="scss">
    section{
        background: var(--section-color-background);
        color: var(--section-color-basic);
    }

    section h1,
    section h2,
    section h3,
    section h4,
    section h5,
    section h6 {
        color:inherit;
    }

    .btn {
    display: inline-block;
    padding: 15px 20px;
    background: var(--section-color-action);
    color: var(--section-color-basic);
    border-radius: var(--radius-buttons);

    &:hover {
        background: var(--section-color-active);
        color: var(--section-color-basic);
    }

    &.btn--secondary {
        color: var(--section-color-action);
        background: transparent;

        &:hover {
        color: var(--section-color-active);
        }
    }
    }

</style>
